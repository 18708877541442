<template>
	<AppLayout>
		<div class="page">
			<div style="height: 100px;"></div>
	    <!-- 内容区域 -->
	    <div class="content">
	      <div class="one-1" id="oneD">
	        <div class="one-1-1">
	          <p>1. 前言</p>
	          <p>
	            本服务政策仅应用于中国大陆地区官方渠道销售的宁美产品及其外设配件。宁美授权第三方平台销售的产品，仅提供保内维修服务，退换货服务请联系经销商处理。
	          </p>
	        </div>
	      </div>
	      <div class="two-1" id="oneD">
	        <div class="two-1-1">
	          <p>2. 产品保修期限</p>
	          <!-- <p><img src="../images/111.png" alt="" /></p>-->
	          <el-table
	            :data="tableData[0]"
	            border
	            size="medium"
	            :header-cell-style="{ borderColor: ' #000 ', color: ' #000 ' }"
	            :cell-style="{ borderColor: '#000', color: '#000' }"
	            class="elTable"
	          >
	            <el-table-column
	              align="center"
	              prop="category"
	              label="类别"
	              width="120"
	            />
	            <el-table-column
	              header-align="center"
	              prop="category_son"
	              label="子类别"
	              width="auto"
	            />
	            <el-table-column
	              align="center"
	              prop="date"
	              label="保修期限"
	              width="120"
	            />
	          </el-table>
	          <p>
	            注：上述为一般情况的产品保修期限，如您购买有延保服务，或购买时有其他服务要求导致保修期限与上述不一致的情况，请以销售合同或产品订单内包含的服务政策说明为准。
	          </p>
	        </div>
	      </div>
	      <div class="three-1" id="oneD">
	        <div class="three-1-1">
	          <p>3. 服务方式</p>
	          <!-- <p><img src="../images/2.png" alt="" /></p>-->
	          <el-table
	            :data="tableData[1]"
	            border
	            size="medium"
	            :header-cell-style="{ borderColor: '#000', color: '#000' }"
	            :cell-style="{ borderColor: '#000', color: '#000' }"
	            class="elTable"
	          >
	            <el-table-column
	              align="center"
	              prop="server_methods"
	              label="服务方式"
	              width="120"
	            />
	            <el-table-column
	              header-align="center"
	              prop="server_explain"
	              label="服务说明"
	              width="auto"
	            />
	            <el-table-column
	              align="center"
	              prop="money_explain"
	              label="费用说明"
	              width="140"
	            />
	          </el-table>
	        </div>
	      </div>
	      <div class="fore-1" id="oneD">
	        <div class="fore-1-1">
	          <p class="fore-1-1-1">4. 服务时效承诺</p>
	          <p class="fore">1 ）响应时效：</p>
	          <p class="fore">
	            a.
	            当日9:00-17:00期间报修，承诺2H内有宁美售后人员电话联系客户，诊断客户设备故障情况，给出解决方案；
	          </p>
	          <p class="fore">
	            b.
	            当日17:00-次日9:00期间报修，承诺次日10:00前有宁美售后人员电话联系客户，诊断客户设备故障情况，给出解决方案。
	          </p>
	          <p class="fore">2）48H修复：</p>
	          <p class="fore">
	            a.上门服务，宁美承诺在客户报修后48H内（如涉及备件，则以备件到达48H内为准）安排售后工程师上门为用户调试维修解决故障；
	          </p>
	          <p class="fore">
	            b.寄修服务，宁美承诺在收到客户故障设备后48H内（如涉及备件，则以备件到达48H内为准）将故障设备调试维修完成，并按客户指定地址寄出。
	          </p>
	          <p class="fore">
	            c.远程调试服务，宁美承诺最晚于客户报修次日为客户安排技术支持调试解决问题。
	          </p>
	          <p class="fore">
	            d.注：以上为一般情况服务时效，如遇疑难杂症，无法一次解决，需要技术升级的情况，修复时效可能会有滞后，敬请谅解。
	          </p>
	        </div>
	      </div>
	      <div class="five-1" id="oneD">
	        <div class="five-1-1">
	          <p class="five-1-1-1">5.产品保修凭证</p>
	          <!--        <p><img src="../images/3.png" alt="" /></p>-->
	          <el-table
	            :data="tableData[2]"
	            border
	            size="medium"
	            :header-cell-style="{ borderColor: '#000', color: '#000' }"
	            :cell-style="{ borderColor: '#000', color: '#000' }"
	            class="elTable"
	          >
	            <el-table-column
	              align="center"
	              prop="text"
	              label="保修凭证"
	              width="210"
	            />
	            <el-table-column
	              header-align="center"
	              prop="explain"
	              label="说明"
	              width="auto"
	            />
	          </el-table>
	          <p class="five">
	            您可提供以上凭证享受宁美的保修服务，如无法提供以上凭证，则保修起始日期以主机发货日期向后顺延15日为准。
	          </p>
	        </div>
	      </div>
	      <div class="six-1" id="oneD">
	        <div class="six-1-1">
	          <p class="six-1-1-1">6.产品保修政策</p>
	          <!--        <p><img src="../images/4.png" alt="" /></p>-->
	          <el-table
	            :data="tableData[3]"
	            border
	            size="medium"
	            :header-cell-style="{ borderColor: '#000', color: '#000' }"
	            :cell-style="{ borderColor: '#000', color: '#000' }"
	            class="elTable"
	          >
	            <el-table-column
	              align="center"
	              prop="text"
	              label="保修政策"
	              width="210"
	            />
	            <el-table-column
	              header-align="center"
	              prop="explain"
	              label="说明"
	              width="auto"
	            />
	          </el-table>
	          <p class="six">下列场景不属于免费退货、换货、维修：</p>
	          <p class="six">1）超过三包有效期的。</p>
	          <p class="six">
	            2）未按产品使用说明的要求使用、维护、保管而造成损坏的。
	          </p>
	          <p class="six">3）非承担三包的修理者拆动而造成损坏的。</p>
	          <p class="six">
	            4）无有效三包凭证及有效发票的（能够证明该商品在三包有效期内的除外）。
	          </p>
	          <p class="six">5）擅自涂改三包凭证的。</p>
	          <p class="six">
	            6）三包凭证上的产品型号或编号与商品实物不相符合的。
	          </p>
	          <p class="six">7）使用盗版软件造成损坏的。</p>
	          <p class="six">8）使用过程中感染病毒导致损坏的。</p>
	          <p class="six">9）无厂名、厂址、生产日期、产品合格证的。</p>
	          <p class="six">
	            10）因战争、地震、水灾、火灾、暴风雪等不可抗力造成损坏的。
	          </p>
	        </div>
	      </div>
	      <div class="seven-1" id="oneD">
	        <div class="seven-1-1">
	          <p class="seven-1-1-1">7.维修质量承诺</p>
	          <p class="seven">1）整机换货后的三包有效期自换货之日起重新计算。</p>
	          <p class="seven">2）保修范围内维修：</p>
	          <p class="seven">
	            维修完成后主机及更换部件延续原保修期，原保修期不足30天的，按30天计算；
	          </p>
	          <!-- <p class="seven">
							b.主机其它部件（除主要部件及配件）更换：主机延续原保修期，保修期不足
							30 天，按照 30 天计算，更换的部件延续主机剩余保修期或 90
							天质保（以时间较长的方式为准）。
						</p> -->
	          <p class="seven">3）保修范围外维修：</p>
	          <p class="seven">
	            维修完成后主机及更换部件延续原保修期，主机原保修期不足30天的，按30天计算；更换部件保修期不足180天的，按180天计算；
	          </p>
	          <!-- <p class="seven">
							b.主机其它部件（除主要部件及配件）更换：更换的部件延续主机剩余保修期或
							90 天质保（以时间较长的方式为准）。
						</p> -->
	          <p class="seven">4）自设备购买之日起提供 3 年可更换部件的服务</p>
	        </div>
	      </div>
	      <div class="eight-1" id="oneD">
	        <div class="eight-1-1">
	          <p class="eight-1-1-1">8.退换货及维修服务指引</p>
	          <p class="eight">
	            退货、换货、维修前，请核对以下内容是否准备齐全：
	          </p>
	          <!--        <p><img src="../images/5.png" alt="" /></p>-->
	          <el-table
	            :data="tableData[4]"
	            border
	            size="medium"
	            :header-cell-style="{ borderColor: '#000', color: '#000' }"
	            :cell-style="{ borderColor: '#000', color: '#000' }"
	            class="elTable"
	          >
	            <el-table-column
	              align="center"
	              prop="server"
	              label="保修服务"
	              width="auto"
	            />
	            <el-table-column
	              align="center"
	              prop="server1"
	              label="主机、配件齐全"
	              width="auto"
	            />
	            <el-table-column
	              align="center"
	              prop="server2"
	              label="包装完整"
	              width="auto"
	            />
	            <el-table-column
	              align="center"
	              prop="server3"
	              label="发票（未涂改）"
	              width="auto"
	            />
	            <el-table-column
	              align="center"
	              prop="server4"
	              label="赠品（若有）"
	              width="auto"
	            />
	          </el-table>
	          <p class="eight">
	            1）维修前，请您提前备份设备中存储的所有数据和自行安装的软件。维修过程中，数据和软件有丢失、损坏或被格式化的风险，宁美不对您送修、检测的设备中存储的任何数据或软件的损害或丢失负责。
	          </p>
	          <p class="eight">
	            2）维修时，设备须经过宁美售后专业的检测。确认故障后，宁美售后会确定相应的维修方案。对在保修范围内的产品，可以自行选择采取更换、维修或其他方式为您提供本保修政策所承诺的保修服务，如采取维修服务，更换下来的坏件归宁美所有；对于检测无故障的产品，将原样退回。
	          </p>
	          <p class="eight">
	            3）因产品不符合保修条件需收取费用的，宁美售后将向您收取维修费，并提供维修报告。
	          </p>
	        </div>
	      </div>
	    </div>
	    <!-- 导航区域 -->
	    <ul class="navs" ref="navList">
	      <div style="padding-top: 20px">
	        <li :class="{ active: active === 0 }" @click="scrollTo(0)">1.前言</li>
	        <li :class="{ active: active === 1 }" @click="scrollTo(1)">
	          2.产品保修期限
	        </li>
	        <li :class="{ active: active === 2 }" @click="scrollTo(2)">
	          3.产品保修服务方式及费用说明
	        </li>
	        <li :class="{ active: active === 3 }" @click="scrollTo(3)">
	          4.产品保修服务时效承诺
	        </li>
	        <li :class="{ active: active === 4 }" @click="scrollTo(4)">
	          5.产品保修凭证
	        </li>
	        <li :class="{ active: active === 5 }" @click="scrollTo(5)">
	          6.产品保修政策
	        </li>
	        <li :class="{ active: active === 6 }" @click="scrollTo(6)">
	          7.维修质量承诺
	        </li>
	        <li :class="{ active: active === 7 }" @click="scrollTo(7)">
	          8.退换货及维修服务指引
	        </li>
	      </div>
	    </ul>
		</div>
	</AppLayout>
	<HomeBackup />
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import AppLayout from "@/components/AppLayout.vue";
import HomeBackup from "@/views/home/components/HomeBackup.vue";

let active = ref(0); // 当前激活的导航索引
const tableData = [
  //售后服务政策表格数据
  [
    {
      category: "台式机" + "\n" + "主要部件",
      category_son: "主板、CPU、内存、硬盘、显卡、电源",
      date: "3年",
    },
    {
      category: "一体机" + "\n" + "主要部件",
      category_son: "主板、CPU、内存、显示屏、硬盘、显卡、电源",
      date: "3年",
    },
    {
      category: "笔记本\n主要部件",
      category_son: "主板、CPU、内存、显示屏、硬盘、显卡、电源适配器、键盘",
      date: "2年",
    },
    {
      category: "显示器",
      category_son: "",
      date: "3年",
    },
    {
      category: "次要部件",
      category_son:
        "散热器、风扇、机箱（面板接口、开关按键）、指示灯、声卡、网卡、采集卡、解压卡、其他功能扩展卡、磁盘驱动器、软盘驱动器、光盘驱动器等",
      date: "1年",
    },
    {
      category: "外设及配件",
      category_son: "鼠标、键盘、扫描仪、打印机、手写板",
      date: "1年",
    },
    {
      category: "软件",
      category_son: "预置Windows系统（不含试用版）含BIOS、驱动、预装软件",
      date: "1年",
    },
  ],
  [
    {
      server_methods: "上门",
      server_explain:
        "由宁美售后工程师上门为您调试或更换备件，以解决您的电脑故障（个别偏远地区无法提供上门服务的，将为客户提供寄修服务)",
      money_explain: "保内免费上门",
    },
    {
      server_methods: "寄修",
      server_explain:
        "由您将故障设备使用快递物流寄送至宁美指定维修中心，宁美售后维修调试完成再将您的设备按指定地址寄回",
      money_explain: "保内双向免运费",
    },
    {
      server_methods: "远程调试",
      server_explain:
        "由宁美售后技术支持在线为您调试解决您的设备问题（调试过程需要客户配合）",
      money_explain: "免费",
    },
  ],
  [
    {
      text: "纸质或电子发票",
      explain:
        "产品保修起始日期以购机发票开具日为准，若保修有效期的最后一天为法定节假日时，以节假日的次日为有效期的最后一天",
    },
    {
      text: "电子三包凭证",
      explain:
        "您可以通过宁美官方网站https://www.ningmeipc.com或宁美商用微信小程序，点击质保查询，输入产品SN号，查看产品的保修情况",
    },
  ],
  [
    {
      text: "7天免费退货、换货、保修",
      explain:
        "自购机之日起7日内（包含7日），若产品的主机出现非人为损坏的性能故障，可以选择按发票价格一次退清货款或更换同型号同规格的产品，或者选择免费修理",
    },
    {
      text: "15天免费换货、保修",
      explain:
        "自购机之日起第8日至15日（包含15日）内，若产品的主机出现非人为损坏的性能故障，可以选择更换同型号同规格的产品，或者选择免费修理",
    },
    {
      text: "保内维修",
      explain:
        "在保修期内，若产品的主机出现非人为损坏的性能故障，可以享受免费修理服务",
    },
  ],
  [
    {
      server: "退货",
      server1: "√",
      server2: "√",
      server3: "√",
      server4: "√",
    },
    {
      server: "换货",
      server1: "√",
      server2: "√",
      server3: "√",
      server4: "",
    },
    {
      server: "维修",
      server1: "√",
      server2: "",
      server3: "√",
      server4: "",
    },
  ],
];

onMounted(() => {
  // 监听滚动事件
  window.addEventListener("scroll", onScroll, false);
});
onUnmounted(() => {
  // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
  window.removeEventListener("scroll", onScroll);
});
const navList = ref();
// 滚动监听器
const onScroll = () => {
  // 获取所有锚点元素
  const navContents = document.querySelectorAll(".content #oneD");
  // console.log(navContents[2].offsetTop)
  // 所有锚点元素的 offsetTop
  const offsetTopArr = [];
  navContents.forEach((item) => {
    offsetTopArr.push(item.offsetTop);
  });
  // console.log(offsetTopArr, "AlloffsetTop");
  // 获取当前文档流的 scrollTop
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;
  // console.log(offsetTopArr, "offsetTopArr");
  // console.log(scrollTop, "scrollTop");
  // 定义当前点亮的导航下标
  // console.log(scrollTop, "上滚动");
  let navIndex = 0;
  for (let n = 0; n < offsetTopArr.length; n++) {
    // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
    // 那么此时导航索引就应该是n了
    if (scrollTop + 150 >= offsetTopArr[n]) {
      navIndex = n;
    }
  }
  active.value = navIndex;
};
// 跳转到指定索引的元素
const scrollTo = (index) => {
  // console.log(index, "index");
  // 获取目标的 offsetTop
  /*// css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
	const targetOffsetTop = document.querySelector(
		`.content div:nth-child(${index + 1})`
	).offsetTop;*/
  const navContents = document.querySelectorAll(".content #oneD");
  const targetOffsetTop = navContents[index].offsetTop - 100;

  // 获取当前 offsetTop
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉，步子迈大了会扯到蛋
  const STEP = 100;
  console.log(targetOffsetTop, "targetOffsetTop");
  console.log(scrollTop, "scrollTop");
  // 判断是往下滑还是往上滑
  if (scrollTop > targetOffsetTop) {
    // 往上滑
    smoothUp();
  } else {
    // 往下滑
    smoothDown();
  }
  // 定义往下滑函数
  function smoothDown() {
    // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
    if (scrollTop < targetOffsetTop) {
      // 如果和目标相差距离大于等于 STEP 就跳 STEP
      // 否则直接跳到目标点，目标是为了防止跳过了。
      if (targetOffsetTop - scrollTop >= STEP) {
        scrollTop += STEP;
      } else {
        scrollTop = targetOffsetTop;
      }
      document.body.scrollTop = scrollTop;
      document.documentElement.scrollTop = scrollTop;
      // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
      requestAnimationFrame(smoothDown);
    }
  }
  // 定义往上滑函数
  function smoothUp() {
    if (scrollTop > targetOffsetTop) {
      if (scrollTop - targetOffsetTop >= STEP) {
        scrollTop -= STEP;
      } else {
        scrollTop = targetOffsetTop;
      }
      document.body.scrollTop = scrollTop;
      document.documentElement.scrollTop = scrollTop;
      requestAnimationFrame(smoothUp);
    }
  }
};
</script>

<style scoped lang="less">
.page{
	width: 1920px;
	margin: 0 auto;
	.content {
		background-color: white;
		width: 1000px;
		margin-bottom: 40px;
		margin-left: 500px;
		.one-1 {
			width: 920px;
			//height: 120px;
			background-color: #fff;
			.one-1-1 {
				padding-top: 47px;
				margin-left: 80px;
				:nth-child(1) {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					font-weight: bold;
					margin-bottom: 20px;
				}
				:nth-child(2) {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					padding-left: 30px;
				}
			}
		}
		.two-1 {
			width: 920px;
			//height: 560px;
			background-color: #fff;
			.two-1-1 {
				padding-top: 47px;
				margin-left: 80px;
				p:nth-child(1) {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					font-weight: bold;
					margin-bottom: 20px;
				}
				p:nth-child(3) {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					padding-left: 30px;
				}
			}
		}
		.three-1 {
			width: 920px;
			//height: 310px;
			background-color: #fff;
			.three-1-1 {
				padding-top: 30px;
				margin-left: 80px;
				p:nth-child(1) {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					font-weight: bold;
					margin-bottom: 20px;
				}
			}
		}
		.fore-1 {
			width: 920px;
			//height: 480px;
			background-color: #fff;
			.fore-1-1 {
				padding-top: 30px;
				margin-left: 80px;
				.fore-1-1-1 {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					font-weight: bold;
					margin-bottom: 20px;
				}
				.fore {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					margin-bottom: 20px;
					padding-left: 30px;
				}
			}
		}
		.five-1 {
			width: 920px;
			//height: 320px;
			background-color: #fff;
			.five-1-1 {
				padding-top: 30px;
				margin-left: 80px;
				.five-1-1-1 {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					font-weight: bold;
					margin-bottom: 20px;
				}
				.five {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					margin-top: 20px;
					padding-left: 30px;
				}
			}
		}
		.six-1 {
			width: 920px;
			//height: 830px;
			background-color: #fff;
			.six-1-1 {
				padding-top: 30px;
				margin-left: 80px;
				.six-1-1-1 {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					font-weight: bold;
					margin-bottom: 20px;
				}
				.six {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					margin-top: 20px;
					padding-left: 30px;
				}
			}
		}
		.seven-1 {
			width: 920px;
			//height: 360px;
			background-color: #fff;
			.seven-1-1 {
				padding-top: 47px;
				margin-left: 80px;
				.seven-1-1-1 {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					font-weight: bold;
					margin-bottom: 20px;
				}
				.seven {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					margin-bottom: 20px;
					padding-left: 30px;
				}
			}
		}
		.eight-1 {
			width: 920px;
			//height: 900px;
			background-color: #fff;
			.eight-1-1 {
				padding-top: 30px;
				padding-bottom: 30px;
				margin-left: 80px;
				.eight-1-1-1 {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					font-weight: bold;
					margin-bottom: 20px;
				}
				.eight {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111;
					margin-top: 20px;
					padding-left: 30px;
				}
			}
		}
	}
	//element表格外边框样式
	.elTable {
		border: 1px solid #000;
		margin-bottom: 20px;
	}
	//element表格内容换行 识别\n
	::v-deep .el-table .cell {
		white-space: pre-line;
	}
	/* 导航栏的样式 */
	.navs {
		width: 180px;
		height: 460px;
		position: fixed;
		top: 100px;
		margin-left: 300px;
		background-color: #fff;
	}
	.navs li {
		padding: 20px 20px 0px 20px;
		font-size: 15px;
		cursor: pointer;
		color: #666666;
	}
	/* 当导航被点亮后改变颜色 */
	.navs .active {
		color: #ce1200;
		background-color: #fff;
	}
}
</style>
